import Grid from "@mui/material/Grid";
import {Button, Divider, Switch, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import GameContext from "../../../contexts/GameContext";

function DesktopCreateGame({ onSwitchChange, onCreateGame, newGame, setNewGame }) {
    const {
        players,
    } = useContext(GameContext);

    const navigate = useNavigate();

    return (
        <Grid
            container
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <Grid
                item
                xs={8}
                style={{
                    borderRadius: 10,
                    border: "2px solid blue",
                    padding: 10,
                }}
            >
                <Grid
                    container
                    style={{
                        justifyContent: "space-between",
                        padding: 10,
                        rowGap: 10,
                    }}
                >
                    <Grid item xs={3}>
                        <Typography
                            style={{
                                height: "100%",
                                alignContent: "center",
                            }}
                        >
                            Spel beschrijving
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                    >
                        <TextField
                            style={{
                                width: "70%",
                            }}
                            value={newGame.description}
                            onChange={e => {
                                if (e.target.value.length <= 20) {
                                    setNewGame(prevNewGame => ({
                                        ...prevNewGame,
                                        description: e.target.value
                                    }));
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    {players.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography>
                                Geen spelers beschikbaar
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Grid
                                container
                                style={{
                                    maxHeight: "50vh",
                                    overflowY: "auto",
                                    border: "1px solid black",
                                    padding: 10,
                                    rowGap: 10,
                                }}
                            >
                                {players.map((player, index) => (
                                    <Grid item xs={12} key={player.id}>
                                        <Grid
                                            container
                                            style={{
                                                rowGap: 10,
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <Typography>{player.username}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>Speler</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Switch
                                                    checked={newGame.players.some(obj => obj.id === player.id)}
                                                    onChange={e => onSwitchChange(e, player, "players")}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>Spelbegeleider</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Switch
                                                    checked={newGame.casters.some(obj => obj.id === player.id)}
                                                    onChange={e => onSwitchChange(e, player, "casters")}
                                                />
                                            </Grid>
                                            {index !== players.length - 1 && (
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            onClick={() => navigate("/home")}
                        >
                            Annuleren
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            onClick={() => onCreateGame()}
                        >
                            Aanmaken
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DesktopCreateGame;
