import {useContext} from "react";
import GameContext from "../../../../contexts/GameContext";
import {Button} from "@mui/material";

function Result() {
    const {
        game,
        goToNextRound,
    } = useContext(GameContext);

    return (
        <Button
            variant="contained"
            style={{
                height: "10%",
                width: "20%",
            }}
            onClick={() => goToNextRound(game.id)}
        >
            {game.players.length - 1 === game.score.eliminated_players.length
                || !game.round_result.main_player_correct ? "naar volgend spel" : "naar volgende ronde"}
        </Button>
    );
}

export default Result;
