import {Typography} from "@mui/material";

function SidePlayersAnswering() {
    return (
        <Typography
            sx={{
                fontWeight: "bold",
            }}
        >
            andere spelers moeten eerst antwoorden
        </Typography>
    );
}

export default SidePlayersAnswering;
