import {useContext, useEffect} from "react";
import GameContext from "../../contexts/GameContext";
import {isMobile} from "react-device-detect";
import DesktopHome from "./components/desktopHome";
import MobileHome from "./components/mobileHome";

function Home() {
    const {
        getGames,
    } = useContext(GameContext);

    useEffect(() => {
        getGames();
    }, []);

    return (
        isMobile ? <MobileHome /> : <DesktopHome />
    );
}

export default Home;
