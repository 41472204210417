import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import GameContext from "../../../contexts/GameContext";
import Grid from "@mui/material/Grid";
import {Button, Switch, TextField, Typography} from "@mui/material";

function MobileCreateGame({ onSwitchChange, onCreateGame, newGame, setNewGame }) {
    const {
        players,
    } = useContext(GameContext);

    const navigate = useNavigate();

    return (
        <Grid container>
            <Grid item xs={12} style={{padding: "3%"}}>
                <Typography
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    Spel beschrijving
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    style={{
                        width: "95%",
                    }}
                    value={newGame.description}
                    onChange={e => {
                        if (e.target.value.length <= 20) {
                            setNewGame(prevNewGame => ({
                                ...prevNewGame,
                                description: e.target.value
                            }));
                        }
                    }}
                />
            </Grid>

            <Grid
                container
                style={{
                    flexGrow: 1,
                    overflowY: "auto",
                    maxHeight: "50vh",
                }}
            >
                {players.length === 0 ? (
                    <Grid item xs={12} style={{padding: "5%"}}>
                        <Typography>
                            Geen spelers beschikbaar
                        </Typography>
                    </Grid>
                ) : (
                    players.map((player, index) => (
                        <Grid
                            container
                            key={index}
                            style={{
                                paddingTop: "5%",
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    style={{
                                        height: "100%",
                                        alignContent: "center",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {player.username}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Speler
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Switch
                                            checked={newGame.players.some(obj => obj.id === player.id)}
                                            onChange={e => onSwitchChange(e, player, "players")}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Spelbegeleider
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Switch
                                            checked={newGame.casters.some(obj => obj.id === player.id)}
                                            onChange={e => onSwitchChange(e, player, "casters")}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                )}
            </Grid>

            <Grid item xs={6}>
                <Button
                    variant="contained"
                    style={{
                        marginTop: "10%",
                        width: "90%",
                        height: "100%",
                    }}
                    onClick={() => navigate("/home")}
                >
                    Annuleren
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    style={{
                        marginTop: "10%",
                        width: "90%",
                        height: "100%",
                    }}
                    onClick={() => onCreateGame()}
                >
                    Aanmaken
                </Button>
            </Grid>
        </Grid>
    )
}

export default MobileCreateGame;
