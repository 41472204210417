import {createContext, useContext, useEffect, useState} from "react";
import AxiosContext from "./AxiosContext";
import NotificationsContext from "./NotificationsContext";
import {useNavigate} from "react-router-dom";

const GameContext = createContext(null);

export default GameContext;

export const GameProvider = ({children}) => {
    const [game, setGame] = useState(null);
    const [games, setGames] = useState([]);
    const [players, setPlayers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [questions, setQuestions] = useState([]);
    const {
        getGameRequest,
        getGamesRequest,
        createGameRequest,
        getPlayersRequest,
        getCategoriesRequest,
        createQuestionRequest,
        startGameRequest,
        goToQuestionRequest,
        goToResultRequest,
        getQuestionsRequest,
        chooseCategoryRequest,
        answerSidePlayerRequest,
        answerMainPlayerRequest,
        goToNextRoundRequest,
        skipQuestionRequest,
    } = useContext(AxiosContext);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (game !== null) {
            const channel = new BroadcastChannel(`gameChannel${game.id}`);
            localStorage.setItem(`gameData${game.id}`, JSON.stringify(game));
            channel.postMessage(game);
            return () => channel.close();
        }
    }, [game]);

    const getGame = (game_id, newAuthUser) => {
        const handleResponse = (response) => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getGameRequest(game_id, handleResponse, handleError, newAuthUser);
    }

    const getGames = (newAuthUser) => {
        const handleResponse = (response) => {
            setGames(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getGamesRequest(handleResponse, handleError, newAuthUser);
    }

    const createGame = (newGame) => {
        const handleResponse = () => {
            navigate("/home");
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        createGameRequest(newGame, handleResponse, handleError);
    }

    const resetGames = () => setGames([]);

    const getPlayers = (newAuthUser) => {
        const handleResponse = (response) => {
            setPlayers(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getPlayersRequest(handleResponse, handleError, newAuthUser);
    }

    const getCategories = (newAuthUser) => {
        const handleResponse = (response) => {
            setCategories(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getCategoriesRequest(handleResponse, handleError, newAuthUser);
    }

    const createQuestion = (question, resetQuestionData) => {
        const handleResponse = () => {
            resetQuestionData();
            getCategories();
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0] === undefined ? "Internal server error" : error.response.data[0]);
        }

        createQuestionRequest(question, handleResponse, handleError);
    }

    const startGame = gameId => {
        const handleResponse = response => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        startGameRequest(gameId, handleResponse, handleError);
    }

    const goToQuestion = gameId => {
        const handleResponse = response => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        goToQuestionRequest(gameId, handleResponse, handleError);
    }

    const goToResult = gameId => {
        const handleResponse = response => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        goToResultRequest(gameId, handleResponse, handleError);
    }

    const getQuestions = newAuthUser => {
        const handleResponse = (response) => {
            setQuestions(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getQuestionsRequest(handleResponse, handleError, newAuthUser);
    }

    const chooseCategory = (gameId, category, newAuthUser) => {
        const handleResponse = (response) => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        chooseCategoryRequest(gameId, category, handleResponse, handleError, newAuthUser);
    }

    const answerSidePlayer = (gameId, answer, newAuthUser) => {
        const handleResponse = (response) => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        answerSidePlayerRequest(gameId, answer, handleResponse, handleError, newAuthUser);
    }

    const answerMainPlayer = (gameId, answer, newAuthUser) => {
        const handleResponse = (response) => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        answerMainPlayerRequest(gameId, answer, handleResponse, handleError, newAuthUser);
    }

    const skipQuestion = (gameId, newAuthUser) => {
        const handleResponse = (response) => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        skipQuestionRequest(gameId, handleResponse, handleError, newAuthUser);
    }

    const goToNextRound = gameId => {
        const handleResponse = response => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        goToNextRoundRequest(gameId, handleResponse, handleError);
    }

    const contextData = {
        game,
        games,
        getGame,
        getGames,
        createGame,
        resetGames,
        players,
        getPlayers,
        categories,
        getCategories,
        createQuestion,
        startGame,
        goToQuestion,
        goToResult,
        questions,
        getQuestions,
        chooseCategory,
        answerSidePlayer,
        answerMainPlayer,
        goToNextRound,
        skipQuestion,
    };

    return (
        <GameContext.Provider value={contextData}>
            {children}
        </GameContext.Provider>
    );
};