import Grid from '@mui/material/Grid';
import {Button, Divider, Switch, TextField, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import AxiosContext from "../../contexts/AxiosContext";
import {Navigate, useNavigate} from "react-router-dom";
import GameContext from "../../contexts/GameContext";
import NotificationsContext from "../../contexts/NotificationsContext";
import {isMobile} from "react-device-detect";
import MobileCreateGame from "./components/mobileCreateGame";
import DesktopCreateGame from "./components/desktopCreateGame";

function CreateGame() {
    const {
        createGame,
        getPlayers,
    } = useContext(GameContext);
    const [newGame, setNewGame] = useState({
        description: "",
        players: [],
        casters: [],
    });
    const {authUser} = useContext(AxiosContext);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);

    useEffect(() => {
        getPlayers();
    }, []);

    const onSwitchChange = (event, player, type) => {
        setNewGame(prevNewGame => {
            const isChecked = event.target.checked;
            const updateArray = type === 'players' ? prevNewGame.players : prevNewGame.casters;
            const removeFromArray = type === 'players' ? prevNewGame.casters : prevNewGame.players;

            return {
                ...prevNewGame,
                [type]: isChecked
                    ? [...updateArray, player]
                    : updateArray.filter(obj => obj.id !== player.id),
                [type === 'players' ? 'casters' : 'players']: removeFromArray.filter(obj => obj.id !== player.id)
            };
        });
    }

    const onCreateGame = () => {
        if (newGame.description === "") enqueueErrorSnackbar("Fill in a description");
        else if (newGame.casters.length === 0) enqueueErrorSnackbar("Select at least one caster");
        else if (newGame.players.length < 2) enqueueErrorSnackbar("Select at least two players");
        else createGame(newGame);
    }

    return !authUser.is_staff ? <Navigate to="/home"/> : (
        isMobile ? (
            <MobileCreateGame
                onSwitchChange={onSwitchChange}
                onCreateGame={onCreateGame}
                newGame={newGame}
                setNewGame={setNewGame}
            />
        ) : (
            <DesktopCreateGame
                onSwitchChange={onSwitchChange}
                onCreateGame={onCreateGame}
                newGame={newGame}
                setNewGame={setNewGame}
            />
        )
    );
}

export default CreateGame;
