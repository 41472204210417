import {useContext} from "react";
import GameContext from "../../../../contexts/GameContext";
import Grid from "@mui/material/Grid";

function Result() {
    const {
        game,
    } = useContext(GameContext);

    return (
        <Grid
            style={{
                backgroundColor: game.round_result.main_player_correct ? "green" : "red",
                width: "100%",
                height: "100%",
            }}
        />
    );
}

export default Result;
