import {useContext} from "react";
import GameContext from "../../../contexts/GameContext";
import AxiosContext from "../../../contexts/AxiosContext";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {Button, Divider, Typography} from "@mui/material";
import {OpenInNew} from "@mui/icons-material";

function MobileHome() {
    const {
        games,
    } = useContext(GameContext);
    const {authUser} = useContext(AxiosContext);

    const navigate = useNavigate();

    return (
        <Grid
            container
            style={{
                width: "100vw",
                height: "90vh",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <Grid item xs={1} />
            <Grid item xs={10}>
                <Divider
                    sx={{
                        margin: "10px",
                        bgcolor: "black",
                    }}
                />
            </Grid>
            <Grid item xs={1} />
            <Grid
                item
                xs={12}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                    }}
                >
                    Spellen
                </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10}>
                <Divider
                    sx={{
                        margin: "10px",
                        bgcolor: "black",
                    }}
                />
            </Grid>
            <Grid item xs={1} />
            {games.length === 0 ? (
                <Grid item xs={12}>
                    <Typography>
                        Geen spellen beschikbaar
                    </Typography>
                </Grid>
            ) : (
                games.map((game, index) => (
                    <Grid item xs={12} key={game.id}>
                        <Grid
                            container
                            style={{
                                cursor: "pointer",
                                rowGap: 10,
                            }}
                            onClick={() => navigate("/game/" + game.id)}
                        >
                            <Grid item xs={4}>
                                <Typography>{game.description}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>{game.status.description}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>{game.main_player.username}</Typography>
                            </Grid>
                            {index !== games.length - 1 && (
                                <Grid item xs={1} />
                            )}
                            {index !== games.length - 1 && (
                                <Grid item xs={10}>
                                    <Divider/>
                                </Grid>
                            )}
                            {index !== games.length - 1 && (
                                <Grid item xs={1} />
                            )}
                        </Grid>
                    </Grid>
                ))
            )}
            <Grid item xs={1} />
            <Grid item xs={10}>
                <Divider
                    sx={{
                        margin: "10px",
                        bgcolor: "black",
                    }}
                />
            </Grid>
            <Grid item xs={1} />
            {authUser.is_staff && (
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        style={{
                            width: "90%",
                            height: "100%",
                        }}
                        onClick={() => navigate("/create_game")}
                    >
                        spel aanmaken
                    </Button>
                </Grid>
            )}
            {(process.env.QUESTIONS_ENABLED || authUser.is_staff) && (
                <Grid item xs={authUser.is_staff ? 4 : 6}>
                    <Button
                        variant="contained"
                        style={{
                            width: "90%",
                            height: "100%",
                        }}
                        onClick={() => navigate("/questions")}
                    >
                        vragen
                    </Button>
                </Grid>
            )}
            {(process.env.QUESTIONS_ENABLED || authUser.is_staff) && (
                <Grid item xs={authUser.is_staff ? 4 : 6}>
                    <Button
                        variant="contained"
                        style={{
                            width: "90%",
                            height: "100%",
                        }}
                        onClick={() => navigate("/create_question")}
                    >
                        vraag aanmaken
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default MobileHome;
