import {useContext} from "react";
import {Navigate} from "react-router-dom";
import AxiosContext from "../contexts/AxiosContext";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";

const PrivateRoute = ({children, hideUsername}) => {
    const {authUser} = useContext(AxiosContext);

    return !authUser.authToken ? <Navigate to="/login"/> : (
        hideUsername ? (
            <Grid
                item
                style={{
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                {children}
            </Grid>
        ) : (
            <Grid
                container
                style={{
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                <Grid
                    item
                    style={{
                        width: "100vw",
                        height: "10vh",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        {authUser.username}
                    </Typography>
                </Grid>
                <Grid
                    item
                    style={{
                        width: "100vw",
                        height: "90vh",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    {children}
                </Grid>
            </Grid>
        )
    );
};

export default PrivateRoute;
