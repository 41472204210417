import Grid from "@mui/material/Grid";
import {Button, Divider, Typography} from "@mui/material";
import {useContext} from "react";
import GameContext from "../../../contexts/GameContext";
import {useNavigate} from "react-router-dom";

function DesktopQuestions() {
    const {
        questions,
    } = useContext(GameContext);

    const navigate = useNavigate();

    return (
        <Grid
            container
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <Grid
                item
                xs={8}
                style={{
                    borderRadius: 10,
                    border: "2px solid blue",
                    padding: 10,
                }}
            >
                <Grid
                    container
                    style={{
                        justifyContent: "center",
                        padding: 10,
                        rowGap: 10,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: "bold"}}>
                            Vragen: {questions.length}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    <Grid
                        container
                        style={{
                            flexGrow: 1,
                            overflowY: "auto",
                            maxHeight: "70vh",
                            padding: 10,
                            rowGap: 10,
                        }}
                    >
                        {questions.map((question, index) => (
                            <Grid key={index} item xs={12}>
                                <Typography>
                                    {question.text}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            style={{
                                width: "100%",
                            }}
                            onClick={() => navigate("/home")}
                        >
                            Terug
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DesktopQuestions;
