import {Typography} from "@mui/material";

function MainPlayerAnswering() {
    return (
        <Typography
            sx={{
                fontWeight: "bold",
            }}
        >
            tijd om te antwoorden is voorbij
        </Typography>
    );
}

export default MainPlayerAnswering;
