import {Typography} from "@mui/material";

function ReadyToStart({ game }) {
    return (
        <Typography
            sx={{
                fontWeight: "bold",
                fontSize: "10vh",
            }}
        >
            speler aan de beurt: {game.main_player.username}
        </Typography>
    );
}

export default ReadyToStart;
