import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

function MainPlayerAnswering({ game }) {
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                style={{
                    height: "25vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: "10vh",
                    }}
                >
                    tijd is voorbij
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    height: "20vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: "5vh",
                    }}
                >
                    {game.question.text}
                </Typography>
            </Grid>
            {game.answers.map(answer => (
                <Grid
                    item
                    xs={6}
                    style={{
                        height: "20vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "3vh",
                        }}
                    >
                        {answer.text}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
}

export default MainPlayerAnswering;
