import {useContext} from "react";
import GameContext from "../../../contexts/GameContext";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {Button, Typography} from "@mui/material";

function MobileQuestions() {
    const {
        questions,
    } = useContext(GameContext);

    const navigate = useNavigate();

    return (
        <Grid container>
            <Grid item xs={12} style={{padding: "3%"}}>
                <Typography
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    Vragen: {questions.length}
                </Typography>
            </Grid>

            <Grid
                container
                style={{
                    flexGrow: 1,
                    overflowY: "auto",
                    maxHeight: "65vh",
                    padding: 10,
                    rowGap: 10,
                }}
            >
                {questions.map((question, index) => (
                    <Grid key={index} item xs={12}>
                        <Typography>
                            {question.text}
                        </Typography>
                    </Grid>
                ))}
            </Grid>

            <Grid item xs={12}>
                <Button
                    variant="contained"
                    style={{
                        marginTop: "5%",
                        width: "50%",
                        height: "80%",
                    }}
                    onClick={() => navigate("/home")}
                >
                    Terug
                </Button>
            </Grid>
        </Grid>
    )
}

export default MobileQuestions;
