import {useContext} from "react";
import GameContext from "../../../../contexts/GameContext";
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

function MainPlayerAnswering() {
    const {
        game,
        answerMainPlayer,
        skipQuestion,
    } = useContext(GameContext);

    return (
        <>
            <Grid
                item
                xs={12}
                style={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        height: "100%",
                        padding: "10px",
                    }}
                >
                    {game.answers.map(answer => (
                        <Grid item xs={6} style={{height: "50%"}}>
                            <Button
                                variant="contained"
                                fullWidth
                                style={{
                                    height: "100%",
                                }}
                                onClick={() => answerMainPlayer(game.id, answer)}
                            >
                                {answer.text}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                style={{
                    height: "20vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "30px",
                }}
            >
                {game.skip_available ? (
                    <Button
                        variant="contained"
                        fullWidth
                        style={{
                            height: "100%",
                        }}
                        onClick={() => skipQuestion(game.id)}
                    >
                        vraag overslaan
                    </Button>
                ) : (
                    <Typography
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        geen skip meer beschikbaar
                    </Typography>
                )}
            </Grid>
        </>
    )
}

export default MainPlayerAnswering;
