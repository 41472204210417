import {useContext} from "react";
import GameContext from "../../../../contexts/GameContext";
import {Button} from "@mui/material";
import Grid from "@mui/material/Grid";

function ChoosingCategory() {
    const {
        game,
        chooseCategory,
    } = useContext(GameContext);

    return (
        <>
            <Grid
                item
                xs={12}
                spacing={2}
                style={{
                    height: "50%",
                    padding: "10px",
                }}
            >
                <Button
                    variant="contained"
                    fullWidth
                    style={{
                        height: "100%",
                    }}
                    onClick={() => chooseCategory(game.id, game.category_option_1)}
                >
                    {game.category_option_1.description}
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                spacing={2}
                style={{
                    height: "50%",
                    padding: "10px",
                }}
            >
                <Button
                    variant="contained"
                    fullWidth
                    style={{
                        height: "100%",
                    }}
                    onClick={() => chooseCategory(game.id, game.category_option_2)}
                >
                    {game.category_option_2.description}
                </Button>
            </Grid>
        </>
    );
}

export default ChoosingCategory;
