import {useContext} from "react";
import {Navigate} from "react-router-dom";
import AxiosContext from "../contexts/AxiosContext";
import Grid from "@mui/material/Grid";

const PublicRoute = ({children}) => {
    const {authUser} = useContext(AxiosContext);

    return authUser.authToken ? <Navigate to="/home"/> : (
        <Grid
            item
            style={{
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            {children}
        </Grid>
    );
};

export default PublicRoute;
