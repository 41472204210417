import Grid from '@mui/material/Grid';
import {useContext, useEffect, useState} from "react";
import AxiosContext from "../../contexts/AxiosContext";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import NotificationsContext from "../../contexts/NotificationsContext";
import {getStatusComponent} from "./definitions";
import ScoreBar from "./components/score_bar";

function MainScreen() {
    const {id} = useParams();
    const {authUser} = useContext(AxiosContext);
    const [game, setGame] = useState(null);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);

    const navigate = useNavigate();

    useEffect(() => {
        const channel = new BroadcastChannel(`gameChannel${id}`);
        const savedGameData = localStorage.getItem(`gameData${id}`);
        if (savedGameData) {
            setGame(JSON.parse(savedGameData));
        }
        channel.onmessage = event => {
            setGame(event.data);
        }
        return () => channel.close();
    }, []);

    return (
        <Grid
            container
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            {!game ? <CircularProgress/> :
                (!game.casters.some(obj => obj.id === authUser.user_id) ? <Navigate to="/home"/> : (
                    <Grid
                        container
                        style={{
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignContent: "center",
                        }}
                    >
                        <ScoreBar game={game}/>
                        <Grid
                            item
                            xs={12}
                            style={{
                                width: "100%",
                                height: "85%",
                                justifyContent: "center",
                                alignContent: "center",
                            }}
                        >
                            {getStatusComponent(game.status.id, enqueueErrorSnackbar, navigate, game)}
                        </Grid>
                    </Grid>
                ))
            }
        </Grid>
    );
}

export default MainScreen;
