import {createContext, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const AxiosContext = createContext(null);

export default AxiosContext;

const getCSRFToken = () => document.cookie.split("; ").find(row => row.startsWith("csrftoken="))?.split("=")[1];

export const AxiosProvider = ({children}) => {
    const navigate = useNavigate();

    const [authUser, setAuthUser] = useState(() =>
        sessionStorage.getItem('authUserTrivia')
            ? JSON.parse(sessionStorage.getItem('authUserTrivia'))
            : {
                username: null,
                authToken: null,
                is_staff: false,
                user_id: null,
            }
    );

    const axiosClient = axios.create();
    axiosClient.defaults.baseURL = `${process.env.REACT_APP_HOST_NAME}/api/`;
    axiosClient.defaults.timeout = 2000;
    axiosClient.defaults.headers.post["X-CSRFToken"] = getCSRFToken();

    axiosClient.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.message.status === 401) {
                setAuthUser({
                    username: null,
                    authToken: null,
                    is_staff: false,
                    user_id: null,
                });
                sessionStorage.removeItem('authUserTrivia');
                navigate('/login');
            }
            console.error('Looks like there was a problem. Status Code: ' + error.message);
            return Promise.reject(error);
        }
    )

    const loginUserRequest = async ({
                                        username,
                                        password,
                                    }, handleResponse, handleError) => {
        const postData = {
            username: username,
            password: password,
        }
        axiosClient.post('/auth/login/', postData)
            .then(response => handleResponse(response))
            .catch(error => handleError(error));
    }

    const getRequest = (url, handleResponse, handleError, newAuthUser) => {
        axiosClient.get(url, {
            headers: {
                'Authorization': `token ${newAuthUser ? newAuthUser.authToken : authUser.authToken}`,
            },
        })
            .then(response => handleResponse(response))
            .catch(error => handleError(error));
    }

    const postRequest = (url, data, handleResponse, handleError, newAuthUser) => {
        axiosClient.post(url, data, {
            headers: {
                'Authorization': `token ${newAuthUser ? newAuthUser.authToken : authUser.authToken}`,
            },
        })
            .then(response => handleResponse(response))
            .catch(error => handleError(error));
    }

    const putRequest = (url, data, handleResponse, handleError, newAuthUser) => {
        axiosClient.put(url, data, {
            headers: {
                'Authorization': `token ${newAuthUser ? newAuthUser.authToken : authUser.authToken}`,
            },
        })
            .then(response => handleResponse(response))
            .catch(error => handleError(error));
    }

    const deleteRequest = (url, handleResponse, handleError, newAuthUser) => {
        axiosClient.delete(url, {
            headers: {
                'Authorization': `token ${newAuthUser ? newAuthUser.authToken : authUser.authToken}`,
            },
        })
            .then(response => handleResponse(response))
            .catch(error => handleError(error));
    }

    const getGameRequest = async (game_id, handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/game/' + game_id, handleResponse, handleError, newAuthUser);
    }

    const getGamesRequest = async (handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/game/', handleResponse, handleError, newAuthUser);
    }

    const getPlayersRequest = async (handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/player/', handleResponse, handleError, newAuthUser);
    }

    const getCategoriesRequest = async (handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/category/', handleResponse, handleError, newAuthUser);
    }

    const createGameRequest = async ({
                                                description,
                                                players,
                                                casters,
                                            }, handleResponse, handleError) => {
        const postData = {
            description: description,
            players: players,
            casters: casters,
        }
        postRequest('/trivia/game/', postData, handleResponse, handleError);
    }

    const createQuestionRequest = async ({
        category,
        createNewCategory,
        text,
        answers,
        creator,
    }, handleResponse, handleError) => {
        const postData = {
            category: category,
            create_new_category: createNewCategory,
            text: text,
            answers: answers,
            creator: creator,
        }
        postRequest('/trivia/question/', postData, handleResponse, handleError);
    }

    const startGameRequest = async (game_id, handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/game/' + game_id + '/start/', handleResponse, handleError, newAuthUser);
    }

    const goToQuestionRequest = async (game_id, handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/game/' + game_id + '/go_to_question/', handleResponse, handleError, newAuthUser);
    }

    const goToResultRequest = async (game_id, handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/game/' + game_id + '/go_to_result/', handleResponse, handleError, newAuthUser);
    }

    const getQuestionsRequest = async (handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/question/', handleResponse, handleError, newAuthUser);
    }

    const chooseCategoryRequest = async (game_id, category, handleResponse, handleError, newAuthUser) => {
        const postData = {
            category: category,
        }
        postRequest('/trivia/game/' + game_id + '/choose_category/', postData, handleResponse, handleError, newAuthUser);
    }

    const answerSidePlayerRequest = async (game_id, answer, handleResponse, handleError, newAuthUser) => {
        const postData = {
            answer: answer,
        }
        postRequest('/trivia/game/' + game_id + '/answer_side_player/', postData, handleResponse, handleError, newAuthUser);
    }

    const answerMainPlayerRequest = async (game_id, answer, handleResponse, handleError, newAuthUser) => {
        const postData = {
            answer: answer,
        }
        postRequest('/trivia/game/' + game_id + '/answer_main_player/', postData, handleResponse, handleError, newAuthUser);
    }

    const goToNextRoundRequest = async (game_id, handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/game/' + game_id + '/go_to_next_round/', handleResponse, handleError, newAuthUser);
    }

    const skipQuestionRequest = async (game_id, handleResponse, handleError, newAuthUser) => {
        getRequest('/trivia/game/' + game_id + '/skip_question/', handleResponse, handleError, newAuthUser);
    }

    const contextData = {
        authUser,
        setAuthUser,
        loginUserRequest,
        getGameRequest,
        getGamesRequest,
        createGameRequest,
        getPlayersRequest,
        getCategoriesRequest,
        createQuestionRequest,
        startGameRequest,
        goToQuestionRequest,
        goToResultRequest,
        getQuestionsRequest,
        chooseCategoryRequest,
        answerSidePlayerRequest,
        answerMainPlayerRequest,
        goToNextRoundRequest,
        skipQuestionRequest,
    };

    return (
        <AxiosContext.Provider value={contextData}>
            {children}
        </AxiosContext.Provider>
    );
};