import {Typography} from "@mui/material";

function ChoosingCategory() {
    return (
        <Typography
            sx={{
                fontWeight: "bold",
            }}
        >
            categorie wordt gekozen
        </Typography>
    );
}

export default ChoosingCategory;
