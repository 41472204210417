import {useContext, useEffect} from "react";
import GameContext from "../../contexts/GameContext";
import {isMobile} from "react-device-detect";
import MobileQuestions from "./components/mobileQuestions";
import DesktopQuestions from "./components/desktopQuestions";

function Questions() {
    const {
        getQuestions,
    } = useContext(GameContext);

    useEffect(() => {
        getQuestions();
    }, []);

    return (
        isMobile ? <MobileQuestions /> : <DesktopQuestions />
    );
}

export default Questions;
