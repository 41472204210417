import {useContext, useEffect, useState} from "react";
import AxiosContext from "../../contexts/AxiosContext";
import GameContext from "../../contexts/GameContext";
import NotificationsContext from "../../contexts/NotificationsContext";
import {isMobile} from "react-device-detect";
import MobileCreateQuestion from "./components/mobileCreateQuestion";
import DesktopCreateQuestion from "./components/desktopCreateQuestion";

const DEFAULT_QUESTION = {
    category: "",
    text: "",
    answers: [
        {
            text: "",
            is_correct: true,
        },
        {
            text: "",
            is_correct: false,
        },
        {
            text: "",
            is_correct: false,
        },
        {
            text: "",
            is_correct: false,
        },
    ],
};

function CreateQuestion() {
    const {
        createQuestion,
        getCategories,
    } = useContext(GameContext);
    const [newQuestion, setNewQuestion] = useState(DEFAULT_QUESTION);
    const [createNewCategory, setCreateNewCategory] = useState(false);
    const {authUser} = useContext(AxiosContext);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);

    useEffect(() => {
        getCategories();
    }, []);

    const resetQuestionData = () => {
        setNewQuestion(DEFAULT_QUESTION);
        setCreateNewCategory(false);
    }

    const onCreateQuestion = () => {
        if (createNewCategory && newQuestion.category === "") enqueueErrorSnackbar("Insert category");
        else if (!createNewCategory && newQuestion.category === "") enqueueErrorSnackbar("Select a category");
        else if (newQuestion.text === "") enqueueErrorSnackbar("No question text");
        else if (newQuestion.answers.some(obj => obj.text === "")) enqueueErrorSnackbar("Fill in all the answers");
        else createQuestion(
            {
                ...newQuestion,
                createNewCategory,
                creator: authUser.user_id,
            }, resetQuestionData
        );
    }

    const handleCategoryButton = () => {
        setCreateNewCategory(prevCreateNewCategory => !prevCreateNewCategory);
        setNewQuestion(prevNewQuestion => ({
            ...prevNewQuestion,
            category: "",
        }));
    }

    const onSwitchChange = (event, index) => {
        if (event.target.checked) {
            setNewQuestion(prevNewQuestion => ({
                ...prevNewQuestion,
                answers: prevNewQuestion.answers.map((prevAnswer, i) =>
                    i === index ? {...prevAnswer, is_correct: true} : {...prevAnswer, is_correct: false}
                )
            }));
        }
    }

    return (
        isMobile ? (
            <MobileCreateQuestion
                onCreateQuestion={onCreateQuestion}
                handleCategoryButton={handleCategoryButton}
                onSwitchChange={onSwitchChange}
                createNewCategory={createNewCategory}
                newQuestion={newQuestion}
                setNewQuestion={setNewQuestion}
            />
        ) : (
            <DesktopCreateQuestion
                onCreateQuestion={onCreateQuestion}
                handleCategoryButton={handleCategoryButton}
                onSwitchChange={onSwitchChange}
                createNewCategory={createNewCategory}
                newQuestion={newQuestion}
                setNewQuestion={setNewQuestion}
            />
        )
    );
}

export default CreateQuestion;
