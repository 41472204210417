import {useContext, useEffect} from "react";
import GameContext from "../../../contexts/GameContext";
import {getStatusComponent} from "./definitions";
import NotificationsContext from "../../../contexts/NotificationsContext";
import {useNavigate} from "react-router-dom";
import {connectWebSocket} from "../utils";

function Caster() {
    const {
        game,
        getGame,
    } = useContext(GameContext);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);

    const navigate = useNavigate();

    useEffect(() => {
        connectWebSocket("caster", game.id, getGame);
    }, []);

    useEffect(() => {
        connectWebSocket("caster", game.id, getGame);
    }, [game.id]);

    return (
        game && getStatusComponent(game.status.id, enqueueErrorSnackbar, navigate)
    );
}

export default Caster;
