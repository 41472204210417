import {Typography} from "@mui/material";

function ReadyToStart() {
    return (
        <Typography
            sx={{
                fontWeight: "bold",
            }}
        >
            spel begint bijna
        </Typography>
    );
}

export default ReadyToStart;
