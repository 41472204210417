import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

function CategorySelected({ game }) {
    return (
        <Grid
            container
            xs={12}
        >
            <Grid
                item
                xs={12}
                sx={{
                    height: "50vh",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: "10vh",
                    }}
                >
                    Geselecteerde categorie
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    height: "50vh",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "8vh",
                    }}
                >
                    {game.question.category.description}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default CategorySelected;
