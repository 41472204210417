import Grid from "@mui/material/Grid";
import {Button, MenuItem, Select, Switch, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import GameContext from "../../../contexts/GameContext";
import AxiosContext from "../../../contexts/AxiosContext";

function MobileCreateQuestion({ onCreateQuestion, handleCategoryButton, onSwitchChange, createNewCategory, newQuestion, setNewQuestion }) {
    const {
        categories,
    } = useContext(GameContext);
    const {authUser} = useContext(AxiosContext);

    const navigate = useNavigate();

    return (
        <Grid container>
            <Grid item xs={12} style={{padding: "3%"}}>
                <Typography
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    Categorie
                </Typography>
            </Grid>
            <Grid item xs={authUser.is_staff ? 8 : 12}>
                {createNewCategory ? (
                    <TextField
                        style={{
                            width: "90%",
                        }}
                        value={newQuestion.category}
                        onChange={e => {
                            if (e.target.value.length <= 20) {
                                setNewQuestion(prevNewQuestion => ({
                                    ...prevNewQuestion,
                                    category: e.target.value
                                }));
                            }
                        }}
                    />
                ) : (
                    <Select
                        style={{
                            width: "90%",
                        }}
                        value={newQuestion.category}
                        onChange={event => setNewQuestion(prevNewQuestion => ({...prevNewQuestion, category: event.target.value}))}
                    >
                        {categories.map(obj => (
                            <MenuItem key={obj.id} value={obj.id}>{obj.description}</MenuItem>
                        ))}
                    </Select>
                )}
            </Grid>
            {authUser.is_staff && (
                <Grid
                    item
                    xs={4}
                    style={{
                        alignContent: "center",
                    }}
                >
                    <Button
                        style={{
                            width: "90%",
                        }}
                        variant="contained"
                        onClick={handleCategoryButton}
                    >
                        {createNewCategory ? "Bestaande" : "Nieuw"}
                    </Button>
                </Grid>
            )}

            <Grid item xs={12} style={{padding: "3%"}}>
                <Typography
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    Vraag
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    style={{
                        width: "95%",
                    }}
                    value={newQuestion.text}
                    onChange={e => {
                        if (e.target.value.length <= 100) {
                            setNewQuestion(prevNewQuestion => ({
                                ...prevNewQuestion,
                                text: e.target.value
                            }));
                        }
                    }}
                />
            </Grid>

            <Grid item xs={12} style={{padding: "3%"}}>
                <Typography
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    Antwoorden
                </Typography>
            </Grid>
            {newQuestion.answers.map((answer, index) => (
                <Grid container key={index}>
                    <Grid item xs={1}>
                        <Typography
                            style={{
                                height: "100%",
                                alignContent: "center",
                                fontWeight: "bold",
                            }}
                        >
                            {index + 1}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            style={{
                                width: "100%",
                                height: "90%",
                            }}
                            InputProps={{
                                style: {
                                    height: '100%',
                                },
                            }}
                            value={answer.text}
                            onChange={e => {
                                if (e.target.value.length <= 20) {
                                    setNewQuestion(prevNewQuestion => ({
                                        ...prevNewQuestion,
                                        answers: prevNewQuestion.answers.map((prevAnswer, i) =>
                                            i === index ? {...prevAnswer, text: e.target.value} : prevAnswer
                                        )
                                    }));
                                }
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            alignContent: "center",
                        }}
                    >
                        <Switch
                            checked={answer.is_correct}
                            onChange={e => onSwitchChange(e, index)}
                        />
                    </Grid>
                </Grid>
            ))}

            <Grid item xs={6}>
                <Button
                    variant="contained"
                    style={{
                        marginTop: "5%",
                        width: "90%",
                        height: "100%",
                    }}
                    onClick={() => navigate("/home")}
                >
                    Annuleren
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    style={{
                        marginTop: "5%",
                        width: "90%",
                        height: "100%",
                    }}
                    onClick={() => onCreateQuestion()}
                >
                    Aanmaken
                </Button>
            </Grid>
        </Grid>
    )
}

export default MobileCreateQuestion;
