import {Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {getTimeRemaining, SIDE_PLAYERS_ANSWERING_TIME} from "../definitions";
import Grid from "@mui/material/Grid";

function SidePlayersAnswering({ game }) {
    const [timeRemaining, setTimeRemaining] = useState(SIDE_PLAYERS_ANSWERING_TIME);

    useEffect(() => {
        setTimeRemaining(getTimeRemaining(game));

        const intervalId = setInterval(() => {
            setTimeRemaining(getTimeRemaining(game));
        }, 100);

        return () => clearInterval(intervalId);
    }, [game.status_changed]);

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                style={{
                    height: "25vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: "20vh",
                    }}
                >
                    {timeRemaining}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    height: "20vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: "5vh",
                    }}
                >
                    {game.question.text}
                </Typography>
            </Grid>
            {game.answers.map(answer => (
                <Grid
                    item
                    xs={6}
                    style={{
                        height: "20vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "3vh",
                        }}
                    >
                        {answer.text}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
}

export default SidePlayersAnswering;
