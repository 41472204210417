import Grid from "@mui/material/Grid";
import {Button, Divider, MenuItem, Select, Switch, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import GameContext from "../../../contexts/GameContext";
import AxiosContext from "../../../contexts/AxiosContext";

function DesktopCreateQuestion({ onCreateQuestion, handleCategoryButton, onSwitchChange, createNewCategory, newQuestion, setNewQuestion }) {
    const {
        categories,
    } = useContext(GameContext);
    const {authUser} = useContext(AxiosContext);

    const navigate = useNavigate();

    return (
        <Grid
            container
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <Grid
                item
                xs={8}
                style={{
                    borderRadius: 10,
                    border: "2px solid blue",
                    padding: 10,
                }}
            >
                <Grid
                    container
                    style={{
                        padding: 10,
                        rowGap: 10,
                    }}
                >
                    <Grid item xs={3}>
                        <Typography
                            style={{
                                height: "100%",
                                alignContent: "center",
                            }}
                        >
                            Categorie
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                    >
                        <Grid container style={{justifyContent: "flex-end"}}>
                            <Grid item xs={9}>
                                {createNewCategory ? (
                                    <TextField
                                        style={{
                                            width: "70%",
                                        }}
                                        value={newQuestion.category}
                                        onChange={e => {
                                            if (e.target.value.length <= 20) {
                                                setNewQuestion(prevNewQuestion => ({
                                                    ...prevNewQuestion,
                                                    category: e.target.value
                                                }));
                                            }
                                        }}
                                    />
                                ) : (
                                    <Select
                                        style={{
                                            width: "70%",
                                        }}
                                        value={newQuestion.category}
                                        onChange={event => setNewQuestion(prevNewQuestion => ({...prevNewQuestion, category: event.target.value}))}
                                    >
                                        {categories.map(obj => (
                                            <MenuItem key={obj.id} value={obj.id}>{obj.description}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            alignContent: "center",
                        }}
                    >
                        {authUser.is_staff && (
                            <Button
                                style={{
                                    alignContent: "center",
                                }}
                                variant="contained"
                                onClick={handleCategoryButton}
                            >
                                {createNewCategory ? "Bestaande" : "Nieuw"}
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            style={{
                                height: "100%",
                                alignContent: "center",
                            }}
                        >
                            Vraag
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                    >
                        <TextField
                            style={{
                                width: "70%",
                            }}
                            value={newQuestion.text}
                            onChange={e => {
                                if (e.target.value.length <= 100) {
                                    setNewQuestion(prevNewQuestion => ({
                                        ...prevNewQuestion,
                                        text: e.target.value
                                    }));
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                            }}
                        />
                    </Grid>
                    {newQuestion.answers.map((answer, index) => (
                        <Grid key={index} container>
                            <Grid item xs={2}>
                                <Typography
                                    style={{
                                        height: "100%",
                                        alignContent: "center",
                                    }}
                                >
                                    Antwoord {index + 1}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                            >
                                <TextField
                                    style={{
                                        width: "90%",
                                    }}
                                    value={answer.text}
                                    onChange={e => {
                                        if (e.target.value.length <= 20) {
                                            setNewQuestion(prevNewQuestion => ({
                                                ...prevNewQuestion,
                                                answers: prevNewQuestion.answers.map((prevAnswer, i) =>
                                                    i === index ? {...prevAnswer, text: e.target.value} : prevAnswer
                                                )
                                            }));
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Switch
                                    checked={answer.is_correct}
                                    onChange={e => onSwitchChange(e, index)}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            onClick={() => navigate("/home")}
                        >
                            Annuleren
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            onClick={() => onCreateQuestion()}
                        >
                            Aanmaken
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DesktopCreateQuestion;
