export const connectWebSocket = (user_role, game_id, getGame) => {
    const socket = new WebSocket(`${process.env.REACT_APP_WS_HOST_NAME}/api/ws/game/${user_role}/${game_id}/`);

    socket.onmessage = (event) => {
        getGame(game_id);
    };

    socket.onclose = () => {
        console.log('WebSocket connection closed');
    };

    return () => {
        socket.close();
    };
}