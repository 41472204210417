import React from 'react';
import ReadyToStart from "./components/ready_to_start";
import ChoosingCategory from "./components/choosing_category";
import CategorySelected from "./components/category_selected";
import SidePlayersAnswering from "./components/side_players_answering";
import MainPlayerAnswering from "./components/main_player_answering";
import QuestionAnswered from "./components/question_answered";
import Result from "./components/result";

const STATUS_TO_COMPONENT_MAPPING = new Map([
    ["0", <ReadyToStart />],
    ["1", <ChoosingCategory />],
    ["2", <CategorySelected />],
    ["3", <SidePlayersAnswering />],
    ["4", <MainPlayerAnswering />],
    ["5", <QuestionAnswered />],
    ["6", <Result />],
]);

export const getStatusComponent = (status_id, enqueueErrorSnackbar, navigate, game) => {
    const component = STATUS_TO_COMPONENT_MAPPING.get(status_id);
    if (component === undefined) {
        enqueueErrorSnackbar("Game has an invalid status");
        navigate("/home");
    } else {
        return React.cloneElement(component, {game});
    }
}

export const SIDE_PLAYERS_ANSWERING_TIME = parseInt(process.env.REACT_APP_SIDE_PLAYERS_ANSWERING_TIME, 10);

export const getTimeRemaining = (game) => {
    const statusChangedDate = new Date(game.status_changed);
    const endTime = new Date(statusChangedDate.getTime() + SIDE_PLAYERS_ANSWERING_TIME * 1000);
    const currentTime = new Date();
    return Math.max(0, Math.floor((endTime - currentTime) / 1000));
}
