import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";

function Result({ game }) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, []);

    const splitEliminatedPlayers = () => {
        if (game.round_result.eliminated_players.length > 5) {
            const half = Math.ceil(game.round_result.eliminated_players.length / 2);
            return [
                game.round_result.eliminated_players.slice(0, half),
                game.round_result.eliminated_players.slice(half),
            ];
        } else {
            return [
                game.round_result.eliminated_players,
                [],
            ]
        }
    };
    const [firstEliminatedRow, secondEliminatedRow] = splitEliminatedPlayers();

    const splitRemainingPlayers = () => {
        const remainingPlayers = game.players.filter(player => player.id !== game.main_player.id).filter(player => !game.score.eliminated_players.some(eliminated_player => player.id === eliminated_player.id));
        if (remainingPlayers.length > 5) {
            const half = Math.ceil(remainingPlayers.length / 2);
            return [
                remainingPlayers.slice(0, half),
                remainingPlayers.slice(half),
            ];
        } else {
            return [
                remainingPlayers,
                [],
            ]
        }
    };
    const [firstRemainingRow, secondRemainingRow] = splitRemainingPlayers();

    return (
        <Grid
            container
            style={{
                backgroundColor: game.round_result.main_player_correct ? "green" : "red",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Grid
                item
                xs={12}
                style={{
                    height: "8vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: "4vh",
                    }}
                >
                    Juiste antwoord: {game.round_result.correct_answer.text}
                </Typography>
            </Grid>

            {game.round_result.main_player_correct && (
                <Grid
                    item
                    xs={12}
                    style={{
                        height: "8vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "4vh",
                        }}
                    >
                        {game.round_result.eliminated_players.length !== 0 ? "Spelers afgevallen deze ronde" : "Geen spelers afgevallen deze ronde"}
                    </Typography>
                </Grid>
            )}
            {game.round_result.main_player_correct && game.round_result.eliminated_players.length !== 0 && (
                <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {firstEliminatedRow.map((player, index) => (
                        <Grid
                            item
                            xs={2}
                            key={player.id}
                            style={{
                                height: "10vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: visible ? 1 : 0,
                                transition: `opacity 0.5s ease ${index * 0.5}s`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "4vh",
                                }}
                            >
                                {player.username}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
            {game.round_result.main_player_correct && game.round_result.eliminated_players.length !== 0 && (
                <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    }}
                >
                    {secondEliminatedRow.map((player, index) => (
                        <Grid
                            item
                            xs={2}
                            key={player.id}
                            style={{
                                height: "10vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: visible ? 1 : 0,
                                transition: `opacity 0.5s ease ${(index + firstEliminatedRow.length) * 0.5}s`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "4vh",
                                }}
                            >
                                {player.username}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
            {game.round_result.main_player_correct && (
                <Grid
                    item
                    xs={12}
                    style={{
                        height: "8vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "4vh",
                        }}
                    >
                        {game.round_result.points === 0.0 ? "Geen slokken verdiend" : `Slokken verdiend: ${game.round_result.points}`}
                    </Typography>
                </Grid>
            )}
            {game.round_result.main_player_correct && game.players.length === game.score.eliminated_players.length && (
                <Grid
                    item
                    xs={12}
                    style={{
                        height: "8vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "4vh",
                        }}
                    >
                        Iedereen is afgevallen
                    </Typography>
                </Grid>
            )}
            {!game.round_result.main_player_correct && (
                <Grid
                    item
                    xs={12}
                    style={{
                        height: "8vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "4vh",
                        }}
                    >
                        Overgebleven spelers
                    </Typography>
                </Grid>
            )}
            {!game.round_result.main_player_correct && (
                <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {firstRemainingRow.map((player, index) => (
                        <Grid
                            item
                            xs={2}
                            key={player.id}
                            style={{
                                height: "10vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: visible ? 1 : 0,
                                transition: `opacity 0.5s ease ${index * 0.5}s`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "4vh",
                                }}
                            >
                                {player.username}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
            {!game.round_result.main_player_correct && (
                <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    }}
                >
                    {secondRemainingRow.map((player, index) => (
                        <Grid
                            item
                            xs={2}
                            key={player.id}
                            style={{
                                height: "10vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                opacity: visible ? 1 : 0,
                                transition: `opacity 0.5s ease ${(index + firstRemainingRow.length) * 0.5}s`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "4vh",
                                }}
                            >
                                {player.username}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
            {((game.round_result.main_player_correct && game.players.length - 1 === game.score.eliminated_players.length) || !game.round_result.main_player_correct) && (
                <Grid
                    item
                    xs={12}
                    style={{
                        height: "8vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "4vh",
                        }}
                    >
                        Aantal slokken om uit te delen: {game.round_result.main_player_correct ? Math.floor(game.score.points).toFixed(0) : Math.floor(game.score.points / 3).toFixed(0)}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default Result;
