import {useContext} from "react";
import GameContext from "../../../../contexts/GameContext";
import Grid from "@mui/material/Grid";
import AxiosContext from "../../../../contexts/AxiosContext";

function Result() {
    const {
        game,
    } = useContext(GameContext);
    const {authUser} = useContext(AxiosContext);

    return (
        <Grid
            style={{
                backgroundColor: game.score.eliminated_players.some(obj => obj.id === authUser.user_id) ? "red" : "green",
                width: "100%",
                height: "100%",
            }}
        />
    );
}

export default Result;
