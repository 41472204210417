import './App.css';
import Login from "./pages/authentication/login";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom"
import PrivateRoute from "./utils/privateRoute";
import PublicRoute from "./utils/publicRoute";
import Contexts from "./Contexts";
import MainScreen from "./pages/main_screen/main_screen";
import Game from "./pages/game/game";
import Home from "./pages/home/home";
import CreateGame from "./pages/create_game/create_game";
import CreateQuestion from "./pages/create_question/create_question";
import Questions from "./pages/questions/questions";

function App() {
    return (
        <div className="App">
            <Router>
                <Contexts>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Navigate to="/home"/>
                            }
                        />
                        <Route
                            path="/login"
                            element={
                                <PublicRoute>
                                    <Login/>
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="/home"
                            element={
                                <PrivateRoute>
                                    <Home/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/game/:id"
                            element={
                                <PrivateRoute>
                                    <Game/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/create_game"
                            element={
                                <PrivateRoute>
                                    <CreateGame/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/main_screen/:id"
                            element={
                                <PrivateRoute hideUsername={true}>
                                    <MainScreen/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/create_question"
                            element={
                                <PrivateRoute>
                                    <CreateQuestion/>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/questions"
                            element={
                                <PrivateRoute>
                                    <Questions/>
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </Contexts>
            </Router>
        </div>
    );
}

export default App;
