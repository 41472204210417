import {Button, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import GameContext from "../../../../contexts/GameContext";
import AxiosContext from "../../../../contexts/AxiosContext";
import {getTimeRemaining, SIDE_PLAYERS_ANSWERING_TIME} from "../../../main_screen/definitions";
import Grid from "@mui/material/Grid";

function SidePlayersAnswering() {
    const [timeRemaining, setTimeRemaining] = useState(SIDE_PLAYERS_ANSWERING_TIME);
    const {authUser} = useContext(AxiosContext);
    const {
        game,
        answerSidePlayer,
    } = useContext(GameContext);

    useEffect(() => {
        setTimeRemaining(getTimeRemaining(game));

        const intervalId = setInterval(() => {
            setTimeRemaining(getTimeRemaining(game));
        }, 100);

        return () => clearInterval(intervalId);
    }, [game.status_changed]);

    return (
        <>
            <Grid
                item
                xs={12}
                style={{
                    height: "20vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: "bold",
                    }}
                >
                    {timeRemaining}
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                style={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {game.side_players_answered.some(obj => obj.id === authUser.user_id) ? (
                    <Typography
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        andere spelers moeten nog antwoorden
                    </Typography>
                ) : (
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            height: "100%",
                            padding: "10px",
                        }}
                    >
                        {game.answers.map(answer => (
                            <Grid item xs={6} style={{height: "50%"}}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    style={{
                                        height: "100%",
                                    }}
                                    onClick={() => answerSidePlayer(game.id, answer)}
                                >
                                    {answer.text}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default SidePlayersAnswering;
