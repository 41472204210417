import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import {SportsBar, Timer} from "@mui/icons-material";

function ScoreBar({ game }) {
    return (
        <Grid
            item
            xs={12}
            style={{
                height: "15%",
            }}
        >
            <Grid
                container
                style={{ height: "100%" }}
            >
                <Grid item xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography
                        variant="h4"
                        style={{
                        fontWeight: "bold",
                        fontSize: "4rem",
                        }}
                    >
                        {game.score.round_number}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Timer style={{ fontSize: "4rem" }} />
                </Grid>

                <Grid item xs={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {game.players.filter(player => player.id !== game.main_player.id).map((player, index) => (
                        <Typography
                            key={index}
                            variant="h4"
                            style={{
                                color: game.score.eliminated_players.some(obj => obj.id === player.id) ? "red" : "green",
                                fontWeight: "bold",
                                fontSize: `calc(4rem / ${Math.max(1, game.players.length / 5)})`,
                                marginRight: index < game.players.length - 1 ? "2rem" : "0",
                            }}
                        >
                            {player.username}
                        </Typography>
                    ))}
                </Grid>

                <Grid item xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <SportsBar style={{ fontSize: "4rem" }} />
                </Grid>
                <Grid item xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography
                        variant="h4"
                        style={{
                        fontWeight: "bold",
                        fontSize: "4rem",
                        }}
                    >
                        {Number.isInteger(game.score.points) ? game.score.points.toFixed(0) : game.score.points.toFixed(2).replace(/\.?0+$/, '')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ScoreBar;
