import {useContext, useEffect} from "react";
import GameContext from "../../../contexts/GameContext";
import {getStatusComponent} from "./definitions";
import NotificationsContext from "../../../contexts/NotificationsContext";
import {useNavigate} from "react-router-dom";
import {connectWebSocket} from "../utils";
import Grid from "@mui/material/Grid";
import AxiosContext from "../../../contexts/AxiosContext";

function SidePlayer() {
    const {
        game,
        getGame,
    } = useContext(GameContext);
    const {authUser} = useContext(AxiosContext);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);


    const navigate = useNavigate();

    useEffect(() => {
        connectWebSocket("side_player", game.id, getGame);
    }, []);

    useEffect(() => {
        connectWebSocket("side_player", game.id, getGame);
    }, [game.id]);

    return (
        game && (
            game.score.eliminated_players.some(obj => obj.id === authUser.user_id) ? (
                <Grid
                    style={{
                        backgroundColor: "red",
                        width: "100%",
                        height: "100%",
                    }}
                />
            ) : getStatusComponent(game.status.id, enqueueErrorSnackbar, navigate)
        )
    );
}

export default SidePlayer;
